import AbstractComponent from "./abstract-component";

const createTemplate = (logo) => {
  return (
    `<section class="popup js--cityListPopup" style="z-index: 1000;">
      <div class="popup__wrap container">
        <button class="popup__closeButton" type="button">Закрыть</button>
        <a class="popup__logo logo" href="/">
          <img class="logo__img" src="${logo.src}" width="120" height="30" alt="${logo.title}">
        </a>
        <p class="popup__text popup__text--note">От выбора города зависят ассортимент, стоимость товара и сроки доставки</p>
        <div class="cities cities--alphabet js--alphabetCityListData">
          <ul class="cities__list"></ul>
        </div>
      </div>
    </section>`
  );
};


export default class CitiesModalComponent extends AbstractComponent {
  constructor({logoData = ``}) {
    super();

    this._logoData = logoData;
  }

  getTemplate() {
    return createTemplate(this._logoData);
  }

  setShowClass() {
    this.getElement().classList.add(`popup--show`);
  }

  setCloseButtonClickHandler(handler) {
    this.getElement().querySelector(`.popup__closeButton`)
    .addEventListener(`click`, handler);
  }

  // setOverlayClickHandler(handler) {
  //   this.getElement().querySelector(`.modal__overlay`)
  //   .addEventListener(`click`, handler);
  // }
}
