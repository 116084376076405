import AbstractComponent from "./abstract-component";

const createTemplate = () => {
  return (
    `<section class="popup popup--mobile">
      <div class="popup__overlay"></div>
      <div class="popup__wrap container">
        <div class="main-header__wrapCenter">
          <div class="contact contact--popup"></div>
          <nav class="main-header__nav nav"></nav>
        </div>
      </div>
    </section>`
  );
};

// {/* <a class="nav__catalogLink" href="/catalog">
// <button class="nav__catalogButton js--catalogButton" type="button">Каталог</button>
// </a> */}


export default class MenuPopupComponent extends AbstractComponent {
  getTemplate() {
    return createTemplate();
  }

  setShowClass() {
    this.getElement().classList.add(`popup--show`);
  }

  setCatalogButtonClickHandler(handler) {
    // this.getElement().querySelector(`.js--catalogButton`)
    // .addEventListener(`click`, (evt) => {
    //   evt.preventDefault();
    //   handler();
    // });
  }

  setOverlayClickHandler(handler) {
    this.getElement().querySelector(`.popup__overlay`)
    .addEventListener(`click`, handler);
  }
}
